/* Contenedor principal */
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  /* Estilo para el formulario */
  .form-container {
    background-color: #ffffff;
    padding: 2rem 2.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  /* Estilo para el título */
  .form-container h1 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  
  /* Estilo para los campos del formulario */
  .form-container .MuiTextField-root {
    margin-bottom: 1rem;
  }
  
  /* Estilo para el botón de submit */
  .form-container .MuiButton-root {
    margin-top: 1rem;
  }
  
  /* Estilo para el mensaje de error */
.form-container .MuiAlert-root {
  margin-top: 1rem;
  width: 100%;
  box-sizing: border-box; /* Asegura que el padding y el border no sumen al ancho total */
}
  
  .app-logo {
    display: block;
    max-width: 100%; /* Asegura que el logo no exceda el ancho del contenedor */
    height: auto; /* Mantiene la proporción de la imagen al ajustar su ancho */
    margin: 0 auto 1.5rem; /* Centrado y con espacio en la parte inferior */
  }